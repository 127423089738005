import axios from "axios";
import { mergeObjects } from "./appUtil";

const initialState = {
    headers : {
        'Accept' : '*',
        'Access-Control-Allow-Origin' : '*',
    },
    reqData : {},
    reqParams : {}
}

export const getSyncCall = async (url,headers,reqParams) => {
    headers = mergeObjects(initialState.headers,headers)

    try {
            if(reqParams!==''){
                url = url.concat('?').concat(reqParams)
            }
            const response = await axios.get(url,{ headers });
            return response.data;
        } catch (errors) {
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const getPaginatedSyncCall = async (url,headers,reqParams) => {
    headers = mergeObjects(initialState.headers,headers)
    try {
            let response = await axios.get(url+'?'+reqParams,{ headers });
            let totalPages = response.data['pageTotal']
            let count = response.data['count'];

            if(totalPages>1){
                let addedParams = '&count='+count+'&anchor=0'
                let newParams = reqParams.concat(addedParams)
                response = await axios.get(url+'?'+newParams,{ headers });
            }
            return response.data;
        } catch (errors) {
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const postSyncCall = async (url,reqData,headers) => {
    headers = mergeObjects(initialState.headers,headers)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = await axios.post(url, reqData, { headers });
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const putSyncCall = async (url,reqData,headers) => {
    headers = mergeObjects(initialState.headers,headers)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = await axios.put(url, reqData, { headers });
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const deleteSyncCall = async (url, headers, reqParams) => {
    headers = mergeObjects(initialState.headers, headers)
    reqParams = mergeObjects(initialState.reqParams,reqParams)
    try {
        const response = await axios.delete(url, { headers }, {param : reqParams});
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const getAsyncCall = async (url, headers, reqParams) => {
    headers = mergeObjects(initialState.headers,headers)
    reqParams = mergeObjects(initialState.reqParams,reqParams)
    try {
            const response = axios.get(url, { params: reqParams }, { headers });
            console.log(response);
            return response.data;
        } catch (errors) {
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const postAsyncCall = async (url, reqData, headers) => {
    headers = mergeObjects(initialState.headers,headers)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = axios.post(url, reqData, {headers});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const putAsyncCall = async (url, reqData, headers) => {
    headers = mergeObjects(initialState.headers,headers)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = axios.put(url, reqData, { headers });
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const deleteAsyncCall = async (url,headers,data) => {
    headers = mergeObjects(initialState.headers, headers)
    data = mergeObjects(initialState.reqParams, data)
    try {
        const response = axios.delete(url, {headers, data});
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}