import React from "react";
import { useDispatch } from "react-redux";
import {
AppHeader, IconButton,
} from "@nike/eds";
import { NikeApp } from "@nike/nike-design-system-icons";

import { setSidePanel } from "reducer/reprocessOrdersApiSlice";
// import ToolbarSlot from "./components/toolbarSlot/ToolbarSlot";

const Header = (props) => {
  const dispatch = useDispatch();

  return (
    <div>
      <AppHeader
        isDark
        logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
        appName="ORDER REPROCESS TOOL"
        // toolbarSlot={<ToolbarSlot />}
        avatarSlot={(
          <IconButton size="medium" variant="ghost" icon="ProfileFilled" onClick={() => { dispatch(setSidePanel(true)) }} />
        )}
      />
    </div>
  );
};

export default Header;
