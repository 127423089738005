export const salesOrgTypes = ["9600", "9700", "9800"];

export const REGIONS = {
	GC: { title: "GC", desc: "Greater China" },
	NA: { title: "NA", desc: "North America" },
	// EMEA: {title: 'EMEA', desc: 'Europe, Middle East & Africa'},
	// OTHERS: {title: 'Others', desc: 'All Other Regions'}
};

export const REGION_OPTIONS = [
	{
		id: "",
		label: "Select Region",
	},
	{
		id: "GC",
		label: "Greater China (GC)",
	},
	{
		id: "NA",
		label: "North America (NA)",
	},
];

export const PRESET_DATES = [
	{ value: "15Min", label: "15 Minutes" },
	{ value: "30Min", label: "30 Minutes" },
	{ value: "1Hr", label: "1 Hour" },
	{ value: "4Hr", label: "4 Hours" },
	{ value: "24Hr", label: "24 Hours" },
	{ value: "yesterday", label: "Yesterday" },
	{ value: "last7Days", label: "Last 7 Days" },
	{ value: "last15Days", label: "Last 15 Days" },
	{ value: "last30Days", label: "Last 30 Days" },
];

export const ORDER_TYPES = [
  { value: "ZCON", label: "ZCON" },
  { value: "ZFRE", label: "ZFRE" },
  { value: "ZSTA", label: "ZSTA" },
  { value: "ZDIG", label: "ZDIG" },
  { value: "ZRST", label: "ZRST" },
  { value: "ZFIN", label: "ZFIN" },
  { value: "STO", label: "STO" },
  { value: "RSTO", label: "RSTO" },
  { value: "ZRFR", label: "ZRFR" },
  { value: "ZSYS", label: "ZSYS" },
	{ value: "EDI", label: "EDI" },
];

const getApiSource = () => {
	let sources = process.env.REACT_APP_DEV_ORDER_REPROCESS_BACKEND_URL;

	switch (process.env.REACT_APP_ENV) {
		case "PROD":
			sources = process.env.REACT_APP_PROD_ORDER_REPROCESS_BACKEND_URL;
			break;
		case "OSCAR":
			sources = process.env.REACT_APP_OSCAR_ORDER_REPROCESS_BACKEND_URL;
			break;
		case "SIERRA":
			sources = process.env.REACT_APP_SIERRA_ORDER_REPROCESS_BACKEND_URL;
			break;
		case "DELTA":
			sources = process.env.REACT_APP_DELTA_ORDER_REPROCESS_BACKEND_URL;
			break;
		case "TEST":
			sources = process.env.REACT_APP_TEST_ORDER_REPROCESS_BACKEND_URL;
			break;
		case "DEV":
		default:
			sources = process.env.REACT_APP_DEV_ORDER_REPROCESS_BACKEND_URL;
	}
	return sources;
};

export const apiSource = getApiSource();
