import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getSyncCall, postSyncCall } from "../utils/apiAdapter";
import moment from "moment";
import { apiSource } from "../constants/dashboardConstants";

export const initialState = {
	flag: false,
	sidePanel: false,
	pagination: {
		offset: 0,
		perPage: 10
	},
	failedOrdersSearchData: {},
	failedOrdersRequestData: {},
	failedOrdersResponseData: {
		success: [],
		status: "idle",
		error: [],
	},
	getorderReprocessSubmitRequestData: {
		orderCollection: [],
	},
	getorderReprocessSubmitResponseData: {},
	setorderReprocessSubmitResponseData: {},
	showOrHideSnackBar: false,
	snackBarMessage: "",
};

export const getFailedOrderDetails = createAsyncThunk(
	"reprocessOrdersSlice/getFailedOrderDetails",
	(input, { getState }) => {
		try {
			const state = getState();

			const api = `${apiSource}/order_mgmt/osd_orders/v3/`; // QA2
			const headers = {
				Authorization: `Bearer ${state.oktaSliceReducer.token}`,
			};

			let params =`${input.data}&count=${input.count}&anchor=${input.page}`;
			const res = getSyncCall(api, headers, params);

			return res;
		} catch (e) {
			console.log(e);
		}
	}
);

export const postFailedOrderReprocess = createAsyncThunk(
	"reprocessOrdersSlice/postFailedOrderReprocess",

	async (_, { getState, dispatch }) => {
		const state = getState(); // has type YourRootState
		const api = `${apiSource}/order_mgmt/osd_orders/v3/process`;

		const headers = {
			Authorization: `Bearer ${state.oktaSliceReducer.token}`,
		};

		const requestBody = state.reprocessReducer.failedOrdersRequestData;
		const res = await postSyncCall(api, requestBody, headers);

		if (res.success && res.success.length > 0) {
			dispatch(setFlag(true));

			if (requestBody.checkAll) {
				const searchParams = state.reprocessReducer.failedOrdersSearchData;
				dispatch(
					setFailedOrderSearchData({
						...searchParams,
						page: searchParams.page + 1,
					})
				);

				dispatch(
					setFailedOrderRequestData({ ...requestBody, checkAll: false })
				);

				dispatch(
					getFailedOrderDetails({
						...searchParams,
						page: searchParams.page + 1,
					})
				);
			}
			// else {
			// 	dispatch(
			// 		getFailedOrderDetails(state.reprocessReducer.failedOrdersSearchData)
			// 	);
			// }
		}

		return res;
	}
);

const reprocessOrdersApiSlice = createSlice({
	name: "reprocessOrdersApiSlice",
	initialState,
	extraReducers: {
		[getFailedOrderDetails.pending]() {},
		[getFailedOrderDetails.fulfilled](state, { payload }) {
			state.getorderReprocessSubmitResponseData = { ...payload };
		},
		[getFailedOrderDetails.rejected](state) {
			state.error = "Rejected";
		},
		[postFailedOrderReprocess.pending]() {
		},
		[postFailedOrderReprocess.fulfilled](state, action) {
			state.failedOrdersResponseData = action.payload;
		},
		[postFailedOrderReprocess.rejected](state) {
			state.error = "Rejected";
		},
	},
	reducers: {
		resetSearchData(state) {
			// return initialState.failedOrdersRequestData;
			state.failedOrdersRequestData = {};
			state.pagination = {...initialState.pagination};
			state.getorderReprocessSubmitResponseData = {};
		},
		resetPagination(state) {
			state.pagination = {...initialState.pagination};
		},
		resetFailedOrderResponseData(state) {
			state.getorderReprocessSubmitResponseData = {};
		},
		setFlag(state, flag) {
			state.flag = flag;
		},
		setSidePanel(state, data) {
			state.sidePanel = data.payload;
		},
		setPagination(state, { payload }) {
			state.pagination = payload;
		},
		getFailedOrderSearchData(state) {
			return state.failedOrdersSearchData;
		},
		setFailedOrderRequestData(state, { payload }) {
			state.failedOrdersRequestData = payload;
		},
		getFailedOrderRequestData(state) {
			return state.failedOrdersRequestData;
		},
		setFailedOrderSearchData(state, { payload }) {
			state.failedOrdersSearchData = {
				data: payload.data,
				page: payload.page,
				count: payload.count,
			};
		},
		updateFailedOrdersResponseData(state, requestedData) {
			let currentState = current(state);
			const failedOrderRequestDataList =
				currentState.failedOrdersRequestData && [
					...currentState.failedOrdersRequestData,
				];
			let responseData = currentState.getorderReprocessSubmitResponseData && [
				...currentState.getorderReprocessSubmitResponseData.orderCollection,
			];
			const newReponseData = responseData.map((order) => {
				if (
					failedOrderRequestDataList &&
					failedOrderRequestDataList.includes(order.orderId)
				) {
					return {
						...order,
						comments: requestedData["payload"].split("|")[0],
						defectId: requestedData["payload"].split("|")[1],
					};
				}
				return order;
			});

			return {
				...state,
				getorderReprocessSubmitResponseData: {
					...state.getorderReprocessSubmitResponseData,
					orderCollection: newReponseData,
				},
			};
		},

		updateFormSearchData(state, targetValue) {
			const inputValue = targetValue.payload;
			if (inputValue.split("|")[0] === "orderId") {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						orderId: inputValue.split("|")[1],
					},
				};
			} else if (inputValue.split("|")[0] === "customerPONumber") {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						poNumber: inputValue.split("|")[1],
					},
				};
			} else if (
				inputValue.split("|")[0] === "orderType" &&
				inputValue.split("|")[0] !== "Select Order Type"
			) {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						orderType: inputValue.split("|")[1],
					},
				};
			} else if (
				inputValue.split("|")[0] === "orderStatus" &&
				inputValue.split("|")[0] !== "Select Status"
			) {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						orderStatus: inputValue.split("|")[1],
					},
				};
			} else if (presetDates.includes(inputValue.split("|")[0])) {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						createdOnAfter: calculateDate(
							inputValue.split("|")[0],
							inputValue.split("|")[1]
						),
						createdOnBefore: new Date(),
					},
				};
			} else if (inputValue === "fromDate") {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						createdOnAfter: inputValue.split("|")[1],
					},
				};
			} else if (inputValue === "toDate") {
				return {
					...state,
					failedOrdersSearchData: {
						...state.failedOrdersSearchData,
						createdOnBefore: inputValue.split("|")[1],
					},
				};
			} else return state.failedOrdersSearchData;
		},
		resetState() {
			return initialState;
		},
		setShowOrHideSnackBar(state, { payload }) {
			state.showOrHideSnackBar = payload;
		},
		setSnackBarMessage(state, { payload }) {
			state.snackBarMessage = payload;
		},
	},
});

export const {
	resetState,
	resetSearchData,
	resetPagination,
	updateFormSearchData,
	setFailedOrderSearchData,
	getFailedOrderSearchData,
	setFailedOrderRequestData,
	getFailedOrderRequestData,
	updateFailedOrdersResponseData,
	resetFailedOrderResponseData,
	setFlag,
	setSidePanel,
	setPagination,
	setShowOrHideSnackBar,
	setSnackBarMessage,
} = reprocessOrdersApiSlice.actions;

export default reprocessOrdersApiSlice.reducer;

/**  Post Order Request
 * {
  "orderIds": [
    "45a9b054-62c5-11ec-90d6-0242ac120013",
    "45a9b2d4-62c5-11ec-90d6-0242ac120003"
  ],
  "userId": "12345sd",
  "defectId": "OMT-12345",
  "comment": "downstream services are down",
  "orderType": "zsta"
}
 */

const presetDates = [
	"15Min",
	"30Min",
	"1Hr",
	"4Hr",
	"24Hr",
	"Yesterday",
	"last7Days",
	"last15Days",
	"last30Days",
];
const currentDate = new Date();
const calculateDate = (eventName, eventValue) => {
	let finalDate = new Date();
	if (eventName === "presetDate") {
		switch (eventValue) {
			case "15Min":
				finalDate = moment(currentDate).subtract(15, "m").toDate();
				break;
			case "30Min":
				finalDate = moment(currentDate).subtract(30, "m").toDate();
				break;
			case "1Hr":
				finalDate = moment(currentDate).subtract(1, "hours").toDate();
				break;
			case "4Hr":
				finalDate = moment(currentDate).subtract(4, "hours").toDate();
				break;
			case "24Hr":
				finalDate = moment(currentDate).subtract(24, "hours").toDate();
				break;
			case "Yesterday":
				finalDate = moment(currentDate).subtract(1, "day").toDate();
				break;
			case "last7Days":
				finalDate = moment(currentDate).subtract(7, "day").toDate();
				break;
			case "last15Days":
				finalDate = moment(currentDate).subtract(15, "day").toDate();
				break;
			case "last30Days":
				finalDate = moment(currentDate).subtract(30, "day").toDate();
				break;
			default:
				break;
		}
	}
	return finalDate;
};
