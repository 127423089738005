import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ENV === 'PROD' ? process.env.REACT_APP_OKTA_PROD_ISSUER : process.env.REACT_APP_OKTA_NON_PROD_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: [
    "order_management:customerorders.failed.reprocessable::read:",
    "order_management:customerorders.failed.reprocessable::update:",
    "openid",
    "profile",
    "email",
  ],
  tokenManager: {
    autoRenew: false,
    storage: "sessionStorage",
  },
  pkce: true,
});

export default oktaAuth;