import React, { lazy } from "react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, useHistory, Switch, Redirect } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import oktaAuth from 'config/okta';

export const getOktaAuth = () => {
  return (oktaAuth && oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? (<div>
    <h5>{oktaAuth.authStateManager._authState.idToken.claims.name}</h5>
    <p>{oktaAuth.authStateManager._authState.idToken.claims.email}</p>
    </div>) : "";
};

export const getOktaAuthUsername = () => {
  return (oktaAuth && oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? oktaAuth.authStateManager._authState.idToken.claims.name : "";
};

const Routes = () => {
  const history = useHistory();

  const OrderReprocess = lazy(() => import("../container/orderReprocess"));

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute
            key="order-reprocess"
            path={["/order-reprocess"]}
            title="Order Reprocess"
            component={OrderReprocess}
            nav={true}
            secure={true}
            breadcrumb="Order Reprocess"
          />
          <Redirect from='/*' to="/order-reprocess" />
        </Switch>
      </Security>
    </>
  );
};

export default Routes;
