import React from "react";
import Routes from "routes/routes";
import Header from 'components/header/Header';
import UserSidePanel from "container/userSidePanel";
import SnackbarLoader from "container/snackbarLoader";
import QualtricsSurvey from "container/qualtricsSurvey";

import "./App.css";
import "styles/dashboard.css";

const App = (props) => {

  return (
    <>
      <div className="mainContainer">
        <div className="appContainer">
          <Header />
          <Routes />
        </div>
        <QualtricsSurvey/>
        <SnackbarLoader/>
        <UserSidePanel />
      </div>
    </>
  );
};

export default App;
