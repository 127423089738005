import { configureStore } from '@reduxjs/toolkit';
import reprocessOrdersApiSlice from 'reducer/reprocessOrdersApiSlice';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import appSlice from 'reducer/appSlice';
import storage from 'redux-persist/lib/storage';
import dashboardSlice from 'reducer/dashboardSlice';
import OktaSlice from 'reducer/oktaSlice';

const reducers = combineReducers({
    reprocessReducer : reprocessOrdersApiSlice,
    appSliceReducer: appSlice,
    dashboardSliceReducer : dashboardSlice,
    oktaSliceReducer: OktaSlice
  });
  const persistConfig = {
    key: 'root',
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, reducers);


export default configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

