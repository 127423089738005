import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestData: {},
  responseData: [],
  selectedOption: "Select Search Option",
  dashBoardStatsCount: {},
  metricsFilterSearchRequest: {
    count: 10,
    anchor: 0,
  },
  metricsFilterSearchData: {
    count: 10,
    anchor: 0,
  },
  byOrderType: {},
  levelSelected: "0",
  selectedKey: "",
  error: "",
  loading: false,
  globalRegion: 'GC'
};

// -------------------------------------------------------------------------------------
const dashboardslice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    resetSearch(state) {
      state.requestData = {};
    },
    resetState(state) {
      return initialState;
    },
    updateLevel(state, level) {
      state.levelSelected = level.payload;
      state.metricsFilterSearchRequest = {
        ...state.metricsFilterSearchRequest,
        level: level.payload,
      };
    },
    updateLevelOnBackClick(state, level) {
      state.levelSelected = level.payload;

      state.metricsFilterSearchRequest = {
        ...state.metricsFilterSearchRequest,
        level: level.payload,
      };

    },
    //pagination logic
    updatePageNum(state, data) {
      let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
      const pageData = data.payload;
      if (pageData) {
        return {
          ...metricsFilterSearchRequest,
          count: pageData.count,
          anchor: pageData.anchor,
        };
      }
    },
    //this is when user clicks on a row
    updateRowOrderId(state, data) {
      let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
      if (state.levelSelected === "Level 1") {
        return { ...metricsFilterSearchRequest, rowOrderId: data.payload };
      }
      if (state.levelSelected === "Level 2") {
        return { ...metricsFilterSearchRequest, rowOrderId: data.payload };
      }
    },
    updatePieOrderType(state, data) {
      state.pieOrderType = data.payload;
    },
    updateSelectedKey(state, key) {
      state.selectedKey = key.payload;
    },
    resetresponseLevel1(state) {
      state.dashboardResponseLevel1 = undefined;
    },
    setGlobalRegion(state, {payload}){
      state.globalRegion = payload;
    },
    updateFailedOrdersResponseData(state, requestedData) {

      let orderSummaryData =
        state.dashboardResponseLevel1 &&
        state.dashboardResponseLevel1.data;
      const newReponseData = orderSummaryData.map((order) => {
        if (
          requestedData.payload.orderList &&
          requestedData.payload.orderList.includes(order.orderId)
        ) {
          return {
            ...order,
            comments: requestedData.payload.comments,
            defectId: requestedData.payload.defectId,
          };
        }
        return order;
      });

      state.dashboardResponseLevel1.data = newReponseData;
    },
  },
});

export const {
  resetresponseLevel1,
  updateSelectedKey,
  updateSelectedKeyTopic,
  updateLevelOnBackClick,
  updatePieOrderType,
  toggleSearchModal,
  resetState,
  resetGrid,
  resetSearch,
  updateLevel,
  updateRowOrderId,
  updatePageNum,
  setGlobalRegion,
  updateFailedOrdersResponseData,
} = dashboardslice.actions;
export default dashboardslice.reducer;
